import React, { useEffect, useState } from "react";

const WebpImage = ({ sources = [], lazy = false, fallback, seo = {}, pictureClassName = '', ...props }) => {
    const [fallbackImage, setFallbackImage] = useState(fallback);
    const [secondFallback, setSecondFallback] = useState('');
    const unavailableImage ='/storage/images/picture_unavailable.jpg'

    const imageError = () => {
        const originalFallbackImage = new Image();
        originalFallbackImage.src = fallback;

        originalFallbackImage.onerror = (e) => {
            if(e.type == 'error'){
                setFallbackImage(unavailableImage);
                return;
            }
        }
        
        originalFallbackImage.onload = (e) => {
            setSecondFallback(originalFallbackImage)
        }
    }
    
    if(fallbackImage == unavailableImage){
        return(
            <picture className={pictureClassName}>
                <source
                    srcSet={`${unavailableImage}`}
                    type={'image/jpg'}
                />
                <img src={fallbackImage} onError={imageError} {...props} loading={lazy ? 'lazy' : 'eager'} {...props} width={seo.width} height={seo.height}/>
            </picture>
        )
    }
    
    if(fallbackImage != unavailableImage){
        return(
            <picture suppressHydrationWarning className={pictureClassName}>
                { !secondFallback &&
                        sources.map((item, index) => (
                            <React.Fragment key={item.width + index}>
                                <source
                                    media={`(min-width: ${item.width}px)`}
                                    srcSet={`${item.path}.webp`}
                                    type={'image/webp'}
                                />

                                <source
                                    media={`(min-width: ${item.width}px)`}
                                    srcSet={`${item.path}.${item.mime}`}
                                    type={item.mimeType}
                                />
                            </React.Fragment>
                        ))
                    
                }
                <img suppressHydrationWarning src={fallbackImage} onError={imageError} {...props} loading={lazy ? 'lazy' : 'eager'} {...props} width={seo.width} height={seo.height}/>
            </picture>
        )
    }
}

export default WebpImage;